const initialData = {
  tasks: {
    'task-1': {
      id: 'task-1',
      title: 'Изучить книгу Грокаем алгоритмы',
      description:
        'Разобраться со структурами данных и алгоритмами и научиться применять их на практике',
      tags: [
        'violet',
        'red',
        'orange',
        'blue',
        'lightgreen',
        'darkblue',
        'yellow',
      ],
      commentIds: ['comment-1', 'comment-2'],
    },
    'task-2': {
      id: 'task-2',
      title: 'Сделать ToDo приложение на React',
      description: '',
      tags: ['lightgreen', 'darkblue', 'yellow'],
      commentIds: [],
    },
    'task-3': {
      id: 'task-3',
      title: 'Выполнить домашку по Node.js',
      description: '',
      tags: ['yellow', 'blue'],
      commentIds: ['comment-3'],
    },
    'task-4': {
      id: 'task-4',
      title: 'Сходить в магазин',
      description: 'Купить помидоры и огурцы',
      tags: [],
      commentIds: [],
    },
    'task-5': {
      id: 'task-5',
      title: 'Пойти погулять',
      description: '',
      tags: ['orange', 'blue'],
      commentIds: [],
    },
    'task-6': {
      id: 'task-6',
      title: 'Добавить ленивую загрузку изображений в портфолио',
      description: '',
      tags: ['orange', 'blue', 'lightgreen'],
      commentIds: ['comment-4'],
    },
    'task-7': {
      id: 'task-7',
      title: 'Внести правки в сайт недвижимости',
      description: 'Подробности в комментах',
      tags: ['violet', 'blue', 'lightgreen', 'darkblue', 'yellow'],
      commentIds: [
        'comment-5',
        'comment-6',
        'comment-7',
        'comment-8',
        'comment-9',
        'comment-10',
        'comment-11',
        'comment-12',
        'comment-13',
        'comment-14',
        'comment-15',
      ],
    },
  },

  columns: {
    new: {
      id: 'new',
      title: 'Новые',
      taskIds: ['task-1', 'task-4', 'task-5'],
    },
    progress: {
      id: 'progress',
      title: 'В процессе',
      taskIds: ['task-2', 'task-6', 'task-7'],
    },
    finished: {
      id: 'finished',
      title: 'Завершенные',
      taskIds: ['task-3'],
    },
  },

  columnsOrder: ['new', 'progress', 'finished'],

  comments: {
    'comment-1': {
      id: 'comment-1',
      name: 'Юрий Лысюк',
      text: 'Продолжить с 175 страницы, реализация алгоритма Дейкстры.',
    },
    'comment-2': {
      id: 'comment-2',
      name: 'Юрий Лысюк',
      text: 'Начать с начала. С последнего момента работы с книгой прошло много времени, я уже все подзабыл.',
    },
    'comment-3': {
      id: 'comment-3',
      name: 'Йода',
      text: 'Многое узнать ты еще можешь, мой юный падаван. Это только начало',
    },
    'comment-4': {
      id: 'comment-4',
      name: 'Юрий Лысюк',
      text: 'Покапать в сторону встроенных или добавляемых возможностей pug-plugin',
    },
    'comment-5': {
      id: 'comment-5',
      name: 'Алексей',
      text: 'Тут выдачу картинки объекта нужно высоту уменьшить и не будет ее так ломать да и слишком большой объект визуально',
    },
    'comment-6': {
      id: 'comment-6',
      name: 'Алексей',
      text: 'Так же высоту картинки сократим в Похожие объекты',
    },
    'comment-7': {
      id: 'comment-7',
      name: 'Алексей',
      text: 'В кабинете есть ИЗБРАННЫЕ ОБЪЕКТЫ этой функцией пользуются риелторы для подборки клиентам объектов по нужным параметрам. Посмотрите можем ли мы туда выводить объекты в том формате как на странице с большим количеством информации',
    },
    'comment-8': {
      id: 'comment-8',
      name: 'Алексей',
      text: 'В таком виде, чтобы видна была вся инфа нужная + чей из агентов объект',
    },
    'comment-9': {
      id: 'comment-9',
      name: 'Алексей',
      text: 'И в готовую карточку вывести сразу телефон агента чей объект и добавил функцию поделиться ватсап + телеграм (взять функцию можно со страницы объекта) и напечатать',
    },
    'comment-10': {
      id: 'comment-10',
      name: 'Алексей',
      text: 'Таким образом в итоге когда прилетает заказ на подбор объекта риелтор опероативно собрал все подходящие объекты в избранное и оттуда смог прозвонить коллег по актуальности кликнув на тел на карточке и поделиться в ватсап или телеграм с клиентом подходящими объектами',
    },
    'comment-11': {
      id: 'comment-11',
      name: 'Алексей',
      text: 'Выдачу объектов на компе если есть возможность переключите на такой вид по умолчанию',
    },
    'comment-12': {
      id: 'comment-12',
      name: 'Алексей',
      text: 'В разделе МОИ ОБЪЕКТЫ давайте чуток соберем в кучу визуально приятней будет работать. Сократим названия и перенесем - Кв и Тел',
    },
    'comment-13': {
      id: 'comment-13',
      name: 'Алексей',
      text: 'На странице добавления объекта тож чуток причешем - где вторичка уберем надпись а новостройка сократим до ( Нов )',
    },
    'comment-14': {
      id: 'comment-14',
      name: 'Алексей',
      text: 'На странице объявления справа сверху функций перевод нужен Print. и тд',
    },
    'comment-15': {
      id: 'comment-15',
      name: 'Юрий Лысюк',
      text: 'Принял, занимаюсь',
    },
  },
};

export default initialData;
