import { useState, useCallback } from 'react';

export function useModal(initialValue) {
  const [isOpenModal, setIsOpenModal] = useState(initialValue);

  const showModal = useCallback(() => {
    setIsOpenModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpenModal(false);
  }, []);

  return { isOpenModal, showModal, closeModal };
}
