import { useState, useCallback } from 'react';

export const useDragDrop = (initialData) => {
  const [columns, setColumns] = useState(initialData);

  const onDragEnd = useCallback(
    (result) => {
      const { draggableId, source, destination } = result;

      if (!destination) return;

      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      ) {
        return;
      }

      const startColumn = columns[source.droppableId];
      const endColumn = columns[destination.droppableId];

      if (startColumn === endColumn) {
        const newTaskIds = [...startColumn.taskIds];

        newTaskIds.splice(source.index, 1);
        newTaskIds.splice(destination.index, 0, draggableId);

        const newColumn = {
          ...startColumn,
          taskIds: newTaskIds,
        };

        const newColumns = {
          ...columns,
          [newColumn.id]: newColumn,
        };

        setColumns(newColumns);

        return;
      }

      const newStartTaskIds = [...startColumn.taskIds];
      newStartTaskIds.splice(source.index, 1);

      const newStartColumn = {
        ...startColumn,
        taskIds: newStartTaskIds,
      };

      const newEndTaskIds = [...endColumn.taskIds];
      newEndTaskIds.splice(destination.index, 0, draggableId);

      const newEndColumn = {
        ...endColumn,
        taskIds: newEndTaskIds,
      };

      const newColumns = {
        ...columns,
        [newStartColumn.id]: newStartColumn,
        [newEndColumn.id]: newEndColumn,
      };

      setColumns(newColumns);
    },
    [columns]
  );

  return { columns, onDragEnd };
};
