import styles from './styles.module.scss';

import classNames from 'classnames';

export const Tag = ({ className, color, children }) => {
  if (!color) return;

  const tagClass = classNames(className, styles.tag, styles[color]);

  return <div className={tagClass}>{children}</div>;
};
