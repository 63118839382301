import { useState, useCallback } from 'react';

export function useTextarea(initialValue) {
  const [value, setValue] = useState(initialValue);

  const handleChange = useCallback((event) => {
    setValue(event.currentTarget.value);
  }, []);

  return { value, handleChange };
}
