const IconDots = ({ className }) => {
  return (
    <svg
      className={className}
      height="3"
      viewBox="0 0 13 3"
      width="13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="1.5" cy="1.5" r="1.5" />
        <circle cx="6.5" cy="1.5" r="1.5" />
        <circle cx="11.5" cy="1.5" r="1.5" />
      </g>
    </svg>
  );
};

const IconMessageText = ({ className }) => {
  return (
    <svg
      className={className}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.16666 0.5H14C14.663 0.5 15.2989 0.763392 15.7678 1.23223C16.2366 1.70107 16.5 2.33696 16.5 3V10.5C16.5 11.163 16.2366 11.7989 15.7678 12.2678C15.2989 12.7366 14.663 13 14 13H10.1783L7.08916 16.0892C6.97261 16.2057 6.82414 16.285 6.66252 16.3172C6.50089 16.3493 6.33336 16.3328 6.18111 16.2697C6.02886 16.2067 5.89873 16.0999 5.80716 15.9629C5.71558 15.8259 5.66669 15.6648 5.66666 15.5V13H3.16666C2.50362 13 1.86773 12.7366 1.39889 12.2678C0.930049 11.7989 0.666656 11.163 0.666656 10.5V3C0.666656 2.33696 0.930049 1.70107 1.39889 1.23223C1.86773 0.763392 2.50362 0.5 3.16666 0.5ZM14 1.33333H3.16666C2.72463 1.33333 2.30071 1.50893 1.98815 1.82149C1.67558 2.13405 1.49999 2.55797 1.49999 3V10.5C1.49999 10.942 1.67558 11.3659 1.98815 11.6785C2.30071 11.9911 2.72463 12.1667 3.16666 12.1667H6.49999V15.5L9.83332 12.1667H14C14.442 12.1667 14.8659 11.9911 15.1785 11.6785C15.4911 11.3659 15.6667 10.942 15.6667 10.5V3C15.6667 2.55797 15.4911 2.13405 15.1785 1.82149C14.8659 1.50893 14.442 1.33333 14 1.33333ZM3.16666 3.83333H14V4.66667H3.16666V3.83333ZM3.16666 6.33333H13.1667V7.16667H3.16666V6.33333ZM3.16666 8.83333H9.83332V9.66667H3.16666V8.83333Z" />
    </svg>
  );
};

const IconMessageAlert = ({ className }) => {
  return (
    <svg
      className={className}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.16666 0.5H14C14.663 0.5 15.2989 0.763392 15.7678 1.23223C16.2366 1.70107 16.5 2.33696 16.5 3V10.5C16.5 11.163 16.2366 11.7989 15.7678 12.2678C15.2989 12.7366 14.663 13 14 13H10.1783L7.08916 16.0892C6.97261 16.2057 6.82414 16.285 6.66252 16.3172C6.50089 16.3493 6.33336 16.3328 6.18111 16.2697C6.02886 16.2067 5.89873 16.0999 5.80716 15.9629C5.71558 15.8259 5.66669 15.6648 5.66666 15.5V13H3.16666C2.50362 13 1.86773 12.7366 1.39889 12.2678C0.930049 11.7989 0.666656 11.163 0.666656 10.5V3C0.666656 2.33696 0.930049 1.70107 1.39889 1.23223C1.86773 0.763392 2.50362 0.5 3.16666 0.5ZM14 1.33333H3.16666C2.72463 1.33333 2.30071 1.50893 1.98815 1.82149C1.67558 2.13405 1.49999 2.55797 1.49999 3V10.5C1.49999 10.942 1.67558 11.3659 1.98815 11.6785C2.30071 11.9911 2.72463 12.1667 3.16666 12.1667H6.49999V15.5L9.83332 12.1667H14C14.442 12.1667 14.8659 11.9911 15.1785 11.6785C15.4911 11.3659 15.6667 10.942 15.6667 10.5V3C15.6667 2.55797 15.4911 2.13405 15.1785 1.82149C14.8659 1.50893 14.442 1.33333 14 1.33333ZM8.16666 3H8.99999V7.16667H8.16666V3ZM8.16666 8.83333H8.99999V10.5H8.16666V8.83333Z" />
    </svg>
  );
};

const IconPlus = ({ className }) => {
  return (
    <svg
      className={className}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00001 10.6667V6.00001M6.00001 6.00001V1.33334M6.00001 6.00001H10.6667M6.00001 6.00001H1.33334"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
    </svg>
  );
};

const IconBack = ({ className }) => {
  return (
    <svg
      className={className}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.65625 24.0625L3.28125 19.6875L7.65625 15.3125"
        strokeWidth="2.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.375 19.6875H24.4727C28.4895 19.6875 31.7188 16.3153 31.7188 12.3047V10.9375"
        strokeWidth="2.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const IconArrow = ({ className }) => {
  return (
    <svg
      className={className}
      width="10"
      height="11"
      viewBox="0 0 10 11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.93145 0.443339C5.09721 0.443339 5.25619 0.509187 5.3734 0.626397C5.49061 0.743607 5.55645 0.902579 5.55645 1.06834L5.55645 8.30959L8.23895 5.62584C8.29706 5.56773 8.36605 5.52163 8.44197 5.49018C8.5179 5.45874 8.59927 5.44255 8.68145 5.44255C8.76363 5.44255 8.84501 5.45874 8.92093 5.49018C8.99686 5.52163 9.06584 5.56773 9.12395 5.62584C9.18206 5.68395 9.22816 5.75294 9.25961 5.82886C9.29106 5.90478 9.30724 5.98616 9.30724 6.06834C9.30724 6.15052 9.29106 6.23189 9.25961 6.30782C9.22816 6.38374 9.18206 6.45273 9.12395 6.51084L5.37395 10.2608C5.3159 10.319 5.24693 10.3652 5.171 10.3967C5.09506 10.4282 5.01366 10.4445 4.93145 10.4445C4.84924 10.4445 4.76784 10.4282 4.69191 10.3967C4.61598 10.3652 4.54701 10.319 4.48895 10.2608L0.738954 6.51084C0.621595 6.39348 0.555664 6.23431 0.555664 6.06834C0.555664 5.90237 0.621595 5.7432 0.738953 5.62584C0.856312 5.50848 1.01548 5.44255 1.18145 5.44255C1.34742 5.44255 1.5066 5.50848 1.62395 5.62584L4.30645 8.30959L4.30645 1.06834C4.30645 0.902579 4.3723 0.743607 4.48951 0.626397C4.60672 0.509187 4.76569 0.443339 4.93145 0.443339Z"
      />
    </svg>
  );
};

const IconClose = ({ className }) => {
  return (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.40994 7L12.7099 2.71C12.8982 2.5217 13.004 2.2663 13.004 2C13.004 1.7337 12.8982 1.47831 12.7099 1.29C12.5216 1.1017 12.2662 0.995911 11.9999 0.995911C11.7336 0.995911 11.4782 1.1017 11.2899 1.29L6.99994 5.59L2.70994 1.29C2.52164 1.1017 2.26624 0.995911 1.99994 0.995911C1.73364 0.995911 1.47824 1.1017 1.28994 1.29C1.10164 1.47831 0.995847 1.7337 0.995847 2C0.995847 2.2663 1.10164 2.5217 1.28994 2.71L5.58994 7L1.28994 11.29C1.19621 11.383 1.12182 11.4936 1.07105 11.6154C1.02028 11.7373 0.994141 11.868 0.994141 12C0.994141 12.132 1.02028 12.2627 1.07105 12.3846C1.12182 12.5064 1.19621 12.617 1.28994 12.71C1.3829 12.8037 1.4935 12.8781 1.61536 12.9289C1.73722 12.9797 1.86793 13.0058 1.99994 13.0058C2.13195 13.0058 2.26266 12.9797 2.38452 12.9289C2.50638 12.8781 2.61698 12.8037 2.70994 12.71L6.99994 8.41L11.2899 12.71C11.3829 12.8037 11.4935 12.8781 11.6154 12.9289C11.7372 12.9797 11.8679 13.0058 11.9999 13.0058C12.132 13.0058 12.2627 12.9797 12.3845 12.9289C12.5064 12.8781 12.617 12.8037 12.7099 12.71C12.8037 12.617 12.8781 12.5064 12.9288 12.3846C12.9796 12.2627 13.0057 12.132 13.0057 12C13.0057 11.868 12.9796 11.7373 12.9288 11.6154C12.8781 11.4936 12.8037 11.383 12.7099 11.29L8.40994 7Z" />
    </svg>
  );
};

export const getIcon = (icon, className) => {
  switch (icon) {
    case 'dots':
      return <IconDots className={className} />;

    case 'messageText':
      return <IconMessageText className={className} />;

    case 'messageAlert':
      return <IconMessageAlert className={className} />;

    case 'plus':
      return <IconPlus className={className} />;

    case 'back':
      return <IconBack className={className} />;

    case 'arrow':
      return <IconArrow className={className} />;

    case 'close':
      return <IconClose className={className} />;

    default:
      return;
  }
};
