import styles from './styles.module.scss';

import classNames from 'classnames';
import { getIcon } from '../Icons/Icons';

export const Button = ({
  className,
  iconClass,
  onClick,
  title,
  icon,
  size,
  color,
}) => {
  const buttonClass = classNames(className, styles.button, {
    [styles[size]]: Boolean(size),
    [styles[color]]: Boolean(color),
  });

  const iconComponent = getIcon(
    icon,
    classNames(iconClass, styles.icon, styles[icon])
  );

  return (
    <button className={buttonClass} onClick={onClick} type="button">
      {Boolean(iconComponent) && iconComponent}

      {Boolean(title) && <span className={styles.title}>{title}</span>}
    </button>
  );
};
