import styles from './styles.module.scss';

import classNames from 'classnames';
import { deleteTask } from '../../actions';

import { useIsOpenMenu } from './useIsOpenMenu';
import { useModal } from '../../hooks/useModal';

import { Button } from '../Button/Button';
import { Modal } from '../Modal/Modal';

export const Column = ({
  innerClassName,
  title,
  hasMenu,
  taskId,
  setIsEditMode,
  children,
}) => {
  const { isOpenMenu, openMenu, closeMenu } = useIsOpenMenu(false);
  const deleteTaskModal = useModal(false);

  const editTask = () => {
    setIsEditMode(true);
    closeMenu();
  };

  const closeDeleteTaskModal = () => {
    deleteTaskModal.closeModal();
    closeMenu();
  };

  const deleteTaskConfirm = () => {
    deleteTask(taskId, deleteTaskModal);
    closeMenu();
  };

  const innerClass = classNames(styles.inner, innerClassName);

  return (
    <>
      <div className={styles.column}>
        <div className={styles.header}>
          <p className={styles.title}>{title}</p>

          {hasMenu && (
            <Button
              className={styles.menu}
              iconClass={styles.iconDots}
              onClick={openMenu}
              icon="dots"
              size="skinny"
              color="transparent"
            />
          )}

          {isOpenMenu && (
            <div className={styles.popup}>
              <Button
                className={styles.buttonDelete}
                onClick={deleteTaskModal.showModal}
                title="Удалить"
                size="skinny"
                color="transparent"
              />

              <Button
                className={styles.buttonEdit}
                onClick={editTask}
                title="Редактировать"
                size="skinny"
                color="transparent"
              />

              <Button
                className={styles.buttonClose}
                iconClass={styles.iconClose}
                onClick={closeMenu}
                icon="close"
                size="skinny"
                color="transparent"
              />
            </div>
          )}
        </div>

        {Boolean(children) && <div className={innerClass}>{children}</div>}
      </div>

      {Boolean(deleteTaskModal.isOpenModal) && (
        <Modal className={styles.confirmModal}>
          <Column innerClassName={styles.columnInner} title="Удалить задачу?">
            <Button onClick={deleteTaskConfirm} title="Да" color="white" />
            <Button onClick={closeDeleteTaskModal} title="Нет" color="white" />
          </Column>
        </Modal>
      )}
    </>
  );
};
