import { useState, useCallback } from 'react';

export function useIsChecked(initialValue) {
  const [isChecked, setIsChecked] = useState(initialValue);

  const toggle = useCallback(() => {
    setIsChecked((currentIsChecked) => !currentIsChecked);
  }, []);

  return { isChecked, toggle };
}
