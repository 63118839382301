import styles from './styles.module.scss';

import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { useMemo } from 'react';

import { Button } from '../Button/Button';

export const Modal = ({ className, openedModal, children }) => {
  const containerElement = useMemo(
    () => document.getElementById('modal-container'),
    []
  );

  const showCloseButton = Boolean(openedModal);

  const modalClass = classNames(styles.body, className);

  return createPortal(
    <>
      <div className={styles.shadow}>
        <div className={modalClass}>
          {showCloseButton && (
            <Button
              className={styles.buttonClose}
              iconClass={styles.iconClose}
              onClick={openedModal.closeModal}
              icon="close"
              size="skinny"
              color="transparent"
            />
          )}

          {Boolean(children) && children}
        </div>
      </div>
    </>,
    containerElement
  );
};
