import styles from './styles.module.scss';

import classNames from 'classnames';

import { useTextarea } from './useTextarea';

export const Textarea = ({
  placeholder,
  text = '',
  rows = 1,
  type,
  disabled,
}) => {
  const initialValue = text;
  const { value, handleChange } = useTextarea(initialValue);

  const textareaClass = classNames(styles.textarea, styles[type], {
    [styles.oneRow]: rows === 1,
  });

  return (
    <textarea
      className={textareaClass}
      onChange={handleChange}
      placeholder={placeholder}
      value={value}
      rows={rows}
      disabled={disabled}
    ></textarea>
  );
};
