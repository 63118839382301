import initialData from './constants/initialData';

const tasks = initialData.tasks;
const comments = initialData.comments;
const columns = initialData.columns;
const columnsOrder = initialData.columnsOrder;

export const getColumns = () => {
  return columns || null;
};

export const getColumnsOrder = () => {
  return columnsOrder || null;
};

export const getTasksByIds = (taskIds) => {
  if (!taskIds) return null;

  return taskIds.map((taskId) => tasks[taskId]);
};

export const getTaskById = (taskId) => {
  return tasks[taskId] || null;
};

export const getCommentsbyIds = (commentIds) => {
  if (!commentIds) return null;

  return commentIds.map((commentId) => comments[commentId]);
};

export const saveTask = (taskId, openedModal) => {
  taskId
    ? console.log(`Задача ${taskId} отредактирована`)
    : console.log('Создана новая задача');

  if (openedModal?.isOpenModal) openedModal.closeModal();
};

export const deleteTask = (taskId, openedModal) => {
  if (!taskId) return;
  console.log(`Задача ${taskId} удалена`);

  if (openedModal?.isOpenModal) openedModal.closeModal();
};

export const saveComment = (taskId, openedModal) => {
  if (!taskId) return;

  console.log(`Новый комментарий к ${taskId} сохранен`);

  if (openedModal?.isOpenModal) openedModal.closeModal();
};

export const deleteComment = (commentId, taskId, openedModal) => {
  if (!commentId || !taskId) return;
  console.log(`Коммент ${commentId} из задачи ${taskId} удален`);

  if (openedModal?.isOpenModal) openedModal.closeModal();
};
