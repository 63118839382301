export default [
  'violet',
  'green',
  'red',
  'orange',
  'blue',
  'lightgreen',
  'darkblue',
  'yellow',
];
