import styles from './styles.module.scss';

import classNames from 'classnames';
import { getIcon } from '../Icons/Icons';

import { Checkbox } from '../Checkbox/Checkbox';
import { Tag } from '../Tag/Tag';

import TAGS from '../../constants/tags';

const tagListClass = classNames(styles.tagList, styles.scrollbar);

const iconArrow = getIcon('arrow', styles.arrow);

export const MultiselectTag = ({ taskId = Math.random(), tags = [] }) => {
  if (!Boolean(TAGS.length)) return;

  const multiSelectId = `{ms_${taskId}}`;

  return (
    <div className={styles.multiselect}>
      <input className={styles.toggle} id={multiSelectId} type="checkbox" />

      <label className={styles.header} htmlFor={multiSelectId}>
        <span>Выбрать тэг</span>

        {iconArrow}
      </label>

      <div className={styles.body}>
        <div className={tagListClass}>
          {TAGS.map((tag) => {
            return (
              <Checkbox key={tag} checked={tags.includes(tag)}>
                <Tag className={styles.tag} color={tag} />
              </Checkbox>
            );
          })}
        </div>
      </div>
    </div>
  );
};
