import styles from './styles.module.scss';

import classNames from 'classnames';

import { Tag } from '../Tag/Tag';
import { Button } from '../Button/Button';

export const TagList = ({
  className,
  deleteAction,
  tags,
  isEditMode,
  isPageView,
}) => {
  if (!Boolean(tags?.length)) return;

  const tagListClass = classNames(className, styles.list, {
    [styles.editMode]: isEditMode || isPageView,
  });

  return (
    <div className={tagListClass}>
      {tags.map((tag) => (
        <Tag className={styles.tag} color={tag} key={tag}>
          {isEditMode && (
            <Button
              className={styles.buttonClose}
              iconClass={styles.iconClose}
              onClick={deleteAction}
              icon="close"
              size="skinny"
              color="transparent"
            />
          )}
        </Tag>
      ))}
    </div>
  );
};
