import { useState, useCallback } from 'react';

export function useIsOpenMenu(initialValue) {
  const [isOpenMenu, setIsOpenMenu] = useState(initialValue);

  const openMenu = useCallback(() => {
    setIsOpenMenu(true);
  }, []);

  const closeMenu = useCallback(() => {
    setIsOpenMenu(false);
  }, []);

  return { isOpenMenu, openMenu, closeMenu };
}
