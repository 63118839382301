import styles from './styles.module.scss';

import classNames from 'classnames';

import { useIsChecked } from './useIsChecked';

export const Checkbox = ({ title, checked, children }) => {
  const initialValue = checked || false;
  const { isChecked, toggle } = useIsChecked(initialValue);

  const checkboxClass = classNames(styles.checkbox, {
    [styles.hasChildren]: Boolean(children),
    [styles.hasTitle]: Boolean(title),
  });

  return (
    <label className={styles.label}>
      <input
        className={styles.input}
        onChange={toggle}
        checked={isChecked}
        type="checkbox"
      />

      {Boolean(children) && children}

      <span className={checkboxClass}>{Boolean(title) && title}</span>
    </label>
  );
};
